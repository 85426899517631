import imgStudio01 from '../../img/studio/imgStudio01.jpg';
import imgStudio02 from '../../img/studio/imgStudio02.jpg';
import imgStudio03 from '../../img/studio/imgStudio03.jpg';
import imgStudio04 from '../../img/studio/imgStudio04.jpg';
import imgStudio05 from '../../img/studio/imgStudio05.jpg';
import imgStudio06 from '../../img/studio/imgStudio06.jpg';
import imgStudio07 from '../../img/studio/imgStudio07.jpg';
import imgStudio08 from '../../img/studio/imgStudio08.jpg';


const imgStudio = [
    {
        alt: "imgStudio01",
        img: imgStudio01
    },
    {
        alt: "imgStudio02",
        img: imgStudio02
    },
    {
        alt: "imgStudio03",
        img: imgStudio03
    },
    {
        alt: "imgStudio04",
        img: imgStudio04
    },
    {
        alt: "imgStudio05",
        img: imgStudio05
    },
    {
        alt: "imgStudio06",
        img: imgStudio06
    },
    {
        alt: "imgStudio07",
        img: imgStudio07
    },
    {
        alt: "imgStudio08",
        img: imgStudio08
    },
    
]

export default imgStudio